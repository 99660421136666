
import { defineComponent, reactive, onMounted, computed, ref, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { SelectOption } from '@/services/SelectOptionService'
import { User, Role, UserRole } from '@/model'
import { AxiosResponse } from 'axios'

export default defineComponent({
  name: 'addOrEditDepartment',
  setup() {
    const recordForm = ref<any>(null)
    const passwordInput = ref<any>(null)
    const route = useRoute()
    const router = useRouter()
    const app = getCurrentInstance()
    const record = reactive({} as User)
    const formReadonly = computed(() => !!route.params.companyId && route.query.readonly == '1' )
    const originalMoible = ref('')
    const originalEmail = ref('')
    const inEdit = ref(false)
    const selectOptions = reactive({
      industryTypes: [] as Array<SelectOption>,
      projectTypes: [] as Array<SelectOption>,
      projectSource: [] as Array<SelectOption>,
      projectStatus: [] as Array<SelectOption>,
      companyType: [] as Array<SelectOption>
    })

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

     /* target parent department */
    const parentDeparmentOptions = reactive([] as Array<SelectOption>)
    function searchParentDeparment(keyword: string) {
      if(!keyword){
        return
      }
      axiosService.get(`odata/departments?$top=20&$skip=0&$select=name,id&$filter=contains(name,'${keyword}')`).then((res: AxiosResponse<{ value: Array<{name: string, id: number}>}>) => {
        const parentDeparment = parentDeparmentOptions.find(c => c.value == record.id)
        parentDeparmentOptions.splice(0, parentDeparmentOptions.length)
        Array.prototype.push.apply(parentDeparmentOptions, res.data.value.map(dept => {
          return {
            key: dept.name,
            value: dept.id
          }
        }))

        if(parentDeparment && !parentDeparmentOptions.find(c => c.value == parentDeparment.value)) {
          parentDeparmentOptions.push(parentDeparment)
        }

        if(!parentDeparmentOptions.some(d => d.value == record.departmentId))
        {
          parentDeparmentOptions.push({key: record.department?.name ?? '', value: record.departmentId ?? ''} as SelectOption)
        }
      })
    }

    const systemRoles = reactive([] as Array<SelectOption>)
    function getSystemRoles() {
      axiosService.get(`odata/roles?$select=displayName,name,id&$orderby=id`).then((res: AxiosResponse<{ value: Array<Role>}>) => {
        Array.prototype.push.apply(systemRoles, res.data.value.map(r => {
          return {
            key: r.displayName,
            value: r.id
          }
        }))
      })
    }

    // email
    const emailRegex = ref(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    // mobile
    const mobileRegex = ref(/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/)
    // password
    const passwordPlaceholder = ref('请设置初始密码')
    const passwordInputType = ref('password')
    const showPasswordIconName = ref('visibility')
    const passwordValidationRules = ref([
      (value: string) => (value && value.length > 0) || '必须设置一个密码',
      (value: string) => (value && value.length >= 6) || '密码最短长度为6',])
    function passwordIconClicked() {
      // generate new password
      record.password = Math.random().toString(32).substring(2);
    }
    function showPlainPassword() {
      if(passwordInputType.value == 'text')
      {
        showPasswordIconName.value = 'visibility'
        passwordInputType.value = 'password'
      } else {
        showPasswordIconName.value = 'visibility_off'
        passwordInputType.value = 'text'
      }
    }


    function submit() {
      if (recordForm.value.validate()) {
        if(!record.id)
        {
          axiosService.get(`odata/users?$filter=userName eq '${record.userName}'`).then(res => {
              if (res.data.value.length > 0) {
                toast({
                  message: `登录名${record.userName}已存在，不能重复添加`,
                  color: 'danger',
                  closeable: true,
                  duration: 5000
                })
                return Promise.reject()
              }
            }).then(() => {
              record.userRoles = record.userRolesForm.map(ur => { return { roleId: ur.value } }) as Array<UserRole>
              return axiosService.post('api/users', record)
            }).then((res: AxiosResponse<User>) => {
              toast({
                message: '添加用户成功！',
                color: 'success',
                closeable: true,
                duration: 3000
              })
              return res.data
            }).then((user: User) => {
              return axiosService.put(`api/users/${user.id}/reset-password`, {newPassword: record.password})
            }).then(() => {
               toast({
                message: '密码设置成功',
                color: 'success',
                closeable: true,
                duration: 3000
              })
              router.push({ path: '/permission/users' })
            })
        }
        else
        {
          axiosService.get(`odata/users/?$filter=email eq '${record.email}' and email ne '${originalEmail.value}'`).then(res => {
              if (res.data.value.length > 0) {
                toast({
                  message: `邮件${record.email}已经被使用，请修改后重新提交！`,
                  color: 'danger',
                  closeable: true,
                  duration: 2000
                })
                return Promise.reject()
              }
            }).then(() => {
              return axiosService.get(`odata/users/?$filter=mobile eq '${record.mobile}' and mobile ne '${originalMoible.value}'`).then(res => {
                if (res.data.value.length > 0) {
                  toast({
                    message: `手机号码${record.mobile}已经被使用，请修改后重新提交！`,
                    color: 'danger',
                    closeable: true,
                    duration: 2000
                  })
                  return Promise.reject()
                }
              })
            }).then(() => {
              record.userRoles = record.userRolesForm.map(ur => { return { roleId: ur.value, userId: record.id } }) as Array<UserRole>
              return axiosService.patch(`api/users/${record.id}`, record)
            }).then(res => {
              toast({
                message: '修改用户信息成功！',
                color: 'success',
                closeable: true,
                duration: 2000
              })
              
            }).then(() => {
              if(record.password && record.password != '') {
                axiosService.put(`api/users/${record.id}/reset-password`, {newPassword: record.password }).then(res => {
                  toast({
                    message: `密码修改成功`,
                    color: 'success',
                    closeable: true,
                    duration: 2000
                  })
                })
              }
              router.push({ path: '/permission/users' })
            })
        }
      }
    }

    function returnToList()
    {
      router.push('/permission/users')
    }

    onMounted(async () => {
      await selectOptionService.waitOptionsLoadComplete()
      selectOptions.industryTypes = selectOptionService.optionCollection.get('industry_type') || []
      selectOptions.projectStatus = selectOptionService.optionCollection.get('ProjectStatus') || []
      selectOptions.projectSource = selectOptionService.optionCollection.get('CompanySource') || []
      selectOptions.companyType = selectOptionService.optionCollection.get('CompanyType') || []
      selectOptions.projectTypes = selectOptionService.optionCollection.get('ImportantEnterpriseType') || []
      getSystemRoles()

      if (route.params.userId) {
        passwordValidationRules.value = [ (value: string) => (value.length == 0 || value.length >= 6) || '密码最短长度为6',]
        passwordPlaceholder.value = '留空则不会改密码'
        inEdit.value = true

        

        axiosService.get(`odata/users(${route.params.userId})?$expand=department($select=id,name),userroles($expand=role($select=id,name,displayName))`).then((res : AxiosResponse<User>) => {
          if (res.data) {
            originalMoible.value = res.data.mobile
            originalEmail.value = res.data.email
            record.id = res.data.id
            record.userName = res.data.userName
            record.fullName = res.data.fullName
            record.title = res.data.title
            record.mobile = res.data.mobile
            record.telephone= res.data.telephone
            record.email= res.data.email
            record.description = res.data.description
            record.departmentId = res.data.departmentId
            record.userRolesForm = res.data.userRoles.map(ur => { return  {id: ur.id, value: ur.role.id, key: ur.role.displayName }})

            if(record.departmentId)
            {
              parentDeparmentOptions.push({key: res.data.department?.name ?? '', value: record.departmentId ?? ''} as SelectOption)
            }
          }
        })



      }
    })

    return {
      record,
      selectOptions,
      recordForm,
      passwordInput,
      formReadonly,
      inEdit,
      submit,
      returnToList,
      parentDeparmentOptions,
      searchParentDeparment,
      systemRoles,
      passwordPlaceholder,
      passwordInputType,
      passwordValidationRules,
      showPasswordIconName,
      passwordIconClicked,
      showPlainPassword,
      emailRegex,
      mobileRegex,
    }
  }
})
